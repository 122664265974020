<template>
  <!-- 详情弹框开始 -->
  <el-dialog title="详情" :visible.sync="DataDetails" width="40%" @close="cancel">
    <el-form :model="applyAliTbs">
      <div style="width:100%;
          height:450px;overflow-y: scroll;">
        <div class="detail">
          <div>
            <el-form-item label="商家支付宝账号" :label-width="LabelWidth">
              <el-input v-model="applyAliTbs.aliPayId" auto-complete="off" style="width:70%"></el-input>
              <div
                v-popover:popover1
                style="margin-left:20px;color:#E04B4D;font-size:8px;"
              >{{applyDescData.a29.length>7?applyDescData.a29.slice(0,7)+'···':applyDescData.a29}}</div>
              <el-popover
                ref="popover1"
                placement="top"
                width="270"
                v-model="visible1"
                trigger="hover"
              >
                <p v-html="applyDescData.a29"></p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="visible1= false">取消</el-button>
                </div>
              </el-popover>
            </el-form-item>
            <el-form-item label="开户类型(必选)" :label-width="LabelWidth">
              <el-radio-group v-model="checked" style="margin-top:12px;">
                <el-radio :label="1">企业</el-radio>
                <el-radio :label="2">个体工商</el-radio>
                <el-radio :label="3">政府及事业单位</el-radio>
                <el-radio :label="4">其他组织</el-radio>
              </el-radio-group>
             <div
                v-popover:popover2
                style="margin-left:20px;color:#E04B4D;font-size:8px;"
              >{{applyDescData.a30.length>7?applyDescData.a30.slice(0,7)+'···':applyDescData.a30}}</div>
              <el-popover
                ref="popover2"
                placement="top"
                width="270"
                v-model="visible2"
                trigger="hover"
              >
                <p v-html="applyDescData.a30"></p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="visible2= false">取消</el-button>
                </div>
              </el-popover>
            </el-form-item>
            <el-form-item label="经营类目" :label-width="LabelWidth">
              <el-select v-model="value" filterable placeholder="请选择" size="mini" style="width:23%">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select
                v-model="value2"
                @change="remoteMethod"
                filterable
                placeholder="请选择"
                size="mini"
                style="width:23%"
              >
                <el-option
                  v-for="item in secondary"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-select
                v-model="value3"
                filterable
                placeholder="请选择"
                size="mini"
                style="width:23%"
              >
                <el-option
                  v-for="item in  minimum"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div
                v-popover:popover3
                style="margin-left:20px;color:#E04B4D;font-size:8px;"
              >{{applyDescData.a108.length>7?applyDescData.a108.slice(0,7)+'···':applyDescData.a108}}</div>
              <el-popover
                ref="popover3"
                placement="top"
                width="270"
                v-model="visible3"
                trigger="hover"
              >
                <p v-html="applyDescData.a108 "></p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="visible3= false">取消</el-button>
                </div>
              </el-popover>
            </el-form-item>
            <el-form-item label="经营资质图片" :label-width="LabelWidth">
              <el-upload
                action="/api/shop/imageUpload"
                name="image"
                list-type="picture-card"
                :on-success="successData"
                :data="uploadData"
                :headers="headers"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="img" v-if="businessQualificationImg">
                <img :src="businessQualificationImg" alt="暂无图片"/>
              </div>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt />
              </el-dialog>
             <div
                v-popover:popover4
                style="margin-left:20px;color:#E04B4D;font-size:8px;"
              >{{applyDescData.a31.length>7?applyDescData.a31.slice(0,7)+'···':applyDescData.a31}}</div>
              <el-popover
                ref="popover4"
                placement="top"
                width="270"
                v-model="visible4"
                trigger="hover"
              >
                <p v-html="applyDescData.a31"></p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="visible4= false">取消</el-button>
                </div>
              </el-popover>
            </el-form-item>
            <el-form-item label="营业执照图片" :label-width="LabelWidth">
              <el-upload
                action="/api/shop/imageUpload"
                name="image"
                list-type="picture-card"
                :on-success="successData2"
                :data="uploadData"
                :headers="headers"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="img" v-if="shopImg">
                <img :src="shopImg" alt="暂无图片" />
              </div>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="businessLicenseImg" alt />
              </el-dialog>
            <div
                v-popover:popover5
                style="margin-left:20px;color:#E04B4D;font-size:8px;"
              >{{applyDescData.a32.length>7?applyDescData.a32.slice(0,7)+'···':applyDescData.a32}}</div>
              <el-popover
                ref="popover5"
                placement="top"
                width="270"
                v-model="visible5"
                trigger="hover"
              >
                <p v-html="applyDescData.a32"></p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="visible5= false">取消</el-button>
                </div>
              </el-popover>
            </el-form-item>
            <el-form-item label="门店图片" :label-width="LabelWidth">
              <el-upload
                action="/api/shop/imageUpload"
                name="image"
                list-type="picture-card"
                :on-success="successData3"
                :data="uploadData"
                :headers="headers"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="img" v-if="shopImg">
                <img :src="shopImg" alt="暂无图片" />
              </div>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="shopImg" alt />
              </el-dialog>
            <div
                v-popover:popover6
                style="margin-left:20px;color:#E04B4D;font-size:8px;"
              >{{applyDescData.a33.length>7?applyDescData.a33.slice(0,7)+'···':applyDescData.a33}}</div>
              <el-popover
                ref="popover6"
                placement="top"
                width="270"
                v-model="visible6"
                trigger="hover"
              >
                <p v-html="applyDescData.a33"></p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="visible6= false">取消</el-button>
                </div>
              </el-popover>
            </el-form-item>
            <el-form-item label="联系人姓名" :label-width="LabelWidth">
              <el-input v-model="applyAliTbs.contactName" auto-complete="off" style="width:70%"></el-input>
            <div
                v-popover:popover7
                style="margin-left:20px;color:#E04B4D;font-size:8px;"
              >{{applyDescData.a34.length>7?applyDescData.a34.slice(0,7)+'···':applyDescData.a34}}</div>
              <el-popover
                ref="popover7"
                placement="top"
                width="270"
                v-model="visible7"
                trigger="hover"
              >
                <p v-html="applyDescData.a34"></p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="visible7= false">取消</el-button>
                </div>
              </el-popover>
            </el-form-item>
            <el-form-item label="联系人电话" :label-width="LabelWidth">
              <el-input v-model="applyAliTbs.contactPhone" auto-complete="off" style="width:70%"></el-input>
             <div
                v-popover:popover8
                style="margin-left:20px;color:#E04B4D;font-size:8px;"
              >{{applyDescData.a35.length>7?applyDescData.a35.slice(0,7)+'···':applyDescData.a35}}</div>
              <el-popover
                ref="popover8"
                placement="top"
                width="100"
                v-model="visible8"
                trigger="hover"
              >
                <p v-html="applyDescData.a35"></p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="visible8= false">取消</el-button>
                </div>
              </el-popover>
            </el-form-item>
            <el-form-item label="联系人电子邮箱" :label-width="LabelWidth">
              <el-input v-model="applyAliTbs.contactEmail" auto-complete="off" style="width:70%"></el-input>
             <div
                v-popover:popover9
                style="margin-left:20px;color:#E04B4D;font-size:8px;"
              >{{applyDescData.a36.length>7?applyDescData.a36.slice(0,7)+'···':applyDescData.a36}}</div>
              <el-popover
                ref="popover9"
                placement="top"
                width="100"
                v-model="visible9"
                trigger="hover"
              >
                <p v-html="applyDescData.a36"></p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="visible9= false">取消</el-button>
                </div>
              </el-popover>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="cencelChr">取 消</el-button>
      <el-button type="primary" @click="sureEdit">确 定</el-button>
    </div>
  </el-dialog>
  <!-- 详情弹框结束 -->
</template>

<script>
import { getToken } from "../../../utils/auth.js";
export default {
  props: ["DataDetail", "cencel", "alipayData", "applyAliTb", "applyDescs"],
  data() {
    return {
      // 详情图片上传
      dialogImageUrl: "",
      dialogVisible: false,
      LabelWidth: "130px", //详情输入框宽度
      DataDetails: false,
      headers: {
        token: getToken().Token
      },
      applyAliTbs: {}, //进件数据

      businessLicenseImg: "", //营业执照图片
      businessQualificationImg: "", //经营资质照片
      shopImg: "", //门店照片       

      businessLicenseImgId: "", //营业执照图片id
      businessQualificationImgId: "", //经营资质照片id
      shopImgId: "", //门店照片id

      // 开户类型单选框
      checked: 0,

      options: [
        {
          //经营类目第一选择框
          value: "企业",
          label: "企业"
        },
        {
          value: "个体工商户",
          label: "个体工商户"
        },
        {
          value: "政府及事业单位",
          label: "政府及事业单位"
        },
        {
          value: "其他组织",
          label: "其他组织"
        }
      ],
      secondary: [
        {
          //经营类目第一选择框
          value: "线下零售",
          label: "线下零售"
        },
        {
          value: "网上购物",
          label: "网上购物"
        },
        {
          value: "居民生活/商业服务",
          label: "居民生活/商业服务"
        },
        {
          value: "休闲娱乐",
          label: "休闲娱乐"
        },
        {
          value: "交通出行/票务旅游",
          label: "交通出行/票务旅游"
        },
        {
          value: "网络媒体/计算机服务/游戏",
          label: "网络媒体/计算机服务/游戏"
        },
        {
          value: "金融",
          label: "金融"
        },
        {
          value: "网络服务平台",
          label: "网络服务平台"
        },
        {
          value: "教育/医疗",
          label: "教育/医疗"
        },
        {
          value: "生活缴费",
          label: "生活缴费"
        },
        {
          value: "收藏拍卖",
          label: "收藏拍卖"
        },
        {
          value: "餐饮",
          label: "餐饮"
        },
        {
          value: "其他",
          label: "其他"
        }
      ], //经营类目第二选择框

      value: "", //经营类目选择框一
      value2: "", //经营类目选择框二
      value3: "", //经营类目选择框三
      applyDescData: {
        a29: "",
        a30: "",
        a31: "",
        a32: "",
        a33: "",
        a34: "",
        a35: "",
        a36: "",
        a108: ""
      }, //查询的字端
      visible1: false, //消息提示
      visible2: false, //消息提示
      visible3: false, //消息提示
      visible4: false, //消息提示
      visible5: false, //消息提示
      visible6: false, //消息提示
      visible7: false, //消息提示
      visible8: false, //消息提示
      visible9: false, //消息提示
    };
  },
  watch: {
    // 传过来的字段
    applyDescs() {
      this.applyDescData = { ...this.applyDescs };
    },
    DataDetail() {
      //监听父级传过来的value
      this.DataDetails = this.DataDetail;
    },
    applyAliTb() {
      this.applyAliTbs = { ...this.applyAliTb };
      // 根据返回value匹配对应的开户类型
      if (this.applyAliTbs.applyType === 1) {
        this.checked = 1;
      } else if (this.applyAliTbs.applyType === 2) {
        this.checked = 2;
      } else if (this.applyAliTbs.applyType === 3) {
        this.checked = 3;
      } else if (this.applyAliTbs.applyType === 4) {
        this.checked = 4;
      }
      // Object.getOwnPropertyNames(this.applyAliTbs).length !== 1是因为没有查询到数据时，请求数据会报错的觉得办法，只有返回有数据才请求
      if (
        this.DataDetails == true &&
        Object.getOwnPropertyNames(this.applyAliTbs).length !== 1
      ) {
        //  businessLicenseImgUrl为营业执照照片  businessQualificationImgUrl为经营资质照片 shopImgUrl为门店图片
        const {
          businessLicenseImgUrl,
          businessQualificationImgUrl,
          shopImgUrl
        } = this.applyAliTbs;
        if (businessLicenseImgUrl !== "---") {
          //营业执照照片
          this.$axios
            .get("/shop/imageDownload", {
              params: { imageId: businessLicenseImgUrl },
              responseType: "arraybuffer"
            })
            .then(res => {
              this.businessLicenseImg =
                "data:image/png;base64," +
                btoa(
                  new Uint8Array(res.data).reduce(
                    (res, byte) => res + String.fromCharCode(byte),
                    ""
                  )
                );
            });
        }
        if (businessQualificationImgUrl !== "---") {
          //营业执照照片
          this.$axios
            .get("/shop/imageDownload", {
              params: { imageId: businessQualificationImgUrl },
              responseType: "arraybuffer"
            })
            .then(res => {
              this.businessQualificationImg =
                "data:image/png;base64," +
                btoa(
                  new Uint8Array(res.data).reduce(
                    (res, byte) => res + String.fromCharCode(byte),
                    ""
                  )
                ); 
            });
        }
        if (shopImgUrl !== "---") {
          //营业执照照片
          this.$axios
            .get("/shop/imageDownload", {
              params: { imageId: shopImgUrl },
              responseType: "arraybuffer"
            })
            .then(res => {
              this.shopImg =
                "data:image/png;base64," +
                btoa(
                  new Uint8Array(res.data).reduce(
                    (res, byte) => res + String.fromCharCode(byte),
                    ""
                  )
                );
            });
        }
        // 经营类目value值分配到三个选择框中
        if (this.applyAliTbs.businessType !== undefined) {
          this.value = this.applyAliTbs.businessType.split("-")[0];
          this.value2 = this.applyAliTbs.businessType.split("-")[1];
          this.value3 = this.applyAliTbs.businessType.split("-")[2];
        }
      }
    }
  },
  computed: {
    minimum() {
      //根据第二个选择框的值渲染第三个选择框的内容
      let minimums = [];
      if (this.value2 === "线下零售") {
        minimums = [
          {
            //经营类目第一选择框
            value: "超市",
            label: "超市"
          },
          {
            value: "便利店",
            label: "便利店"
          },
          {
            value: "自动贩卖机",
            label: "自动贩卖机"
          },
          {
            value: "百货",
            label: "百货"
          },
          {
            value: "数码电器/电脑办公",
            label: "数码电器/电脑办公"
          },
          {
            value: "家具建材/家具厨具",
            label: "家具建材/家具厨具"
          },
          {
            value: "食品生鲜",
            label: "食品生鲜"
          },
          {
            value: "服饰箱包",
            label: "服饰箱包"
          },
          {
            value: "运动户外",
            label: "运动户外"
          },
          {
            value: "美妆个肤",
            label: "美妆个肤"
          },
          {
            value: "母婴用品/儿童玩具",
            label: "母婴用品/儿童玩具"
          },
          {
            value: "计生用品",
            label: "计生用品"
          },
          {
            value: "图书音像/文具乐器",
            label: "图书音像/文具乐器"
          },
          {
            value: "黄金珠宝",
            label: "黄金珠宝"
          },
          {
            value: "钟表眼镜",
            label: "钟表眼镜"
          },
          {
            value: "宠物/宠物用品",
            label: "宠物/宠物用品"
          },
          {
            value: "礼品鲜花/农资绿植",
            label: "礼品鲜花/农资绿植"
          },
          {
            value: "批发业",
            label: "批发业"
          }
        ];
      }
      if (this.value2 === "网上购物") {
        minimums = [
          {
            //经营类目第一选择框
            value: "线上商超",
            label: "线上商超"
          }
        ];
      }
      if (this.value2 === "餐饮") {
        minimums = [
          {
            //经营类目第一选择框
            value: "快餐",
            label: "快餐"
          },
          {
            value: "火锅",
            label: "火锅"
          },
          {
            value: "烧烤",
            label: "烧烤"
          },
          {
            value: "小吃/熟食",
            label: "小吃/熟食"
          },
          {
            value: "其他中餐",
            label: "其他中餐"
          },
          {
            value: "日韩/东南亚菜",
            label: "日韩/东南亚菜"
          },
          {
            value: "西餐",
            label: "西餐"
          },
          {
            value: "咖啡厅",
            label: "咖啡厅"
          },
          {
            value: "甜品饮品",
            label: "甜品饮品"
          },
          {
            value: "烘焙糕点",
            label: "烘焙糕点"
          }
        ];
      }
      if (this.value2 === "居民生活/商业服务") {
        minimums = [
          {
            //经营类目第一选择框
            value: "物流/快递",
            label: "物流/快递"
          },
          {
            value: "咨询/法律咨询/金融咨询等",
            label: "咨询/法律咨询/金融咨询等"
          },
          {
            value: "婚庆/摄影",
            label: "婚庆/摄影"
          },
          {
            value: "装饰/设计",
            label: "装饰/设计"
          },
          {
            value: "家政/维修服务",
            label: "家政/维修服务"
          },
          {
            value: "广告/会展/活动策划",
            label: "广告/会展/活动策划"
          },
          {
            value: "人才中介机构/招聘/猎头",
            label: "人才中介机构/招聘/猎头"
          },
          {
            value: "职业社交/婚介/交友",
            label: "职业社交/婚介/交友"
          },
          {
            value: "房地产",
            label: "房地产"
          },
          {
            value: "烘苗木种植/园林绿化焙糕点",
            label: "苗木种植/园林绿化"
          },
          {
            value: "搬家/回收",
            label: "搬家/回收"
          },
          {
            value: "宠物医院",
            label: "宠物医院"
          },
          {
            value: "共享服务",
            label: "共享服务"
          }
        ];
      }
      if (this.value2 === "休闲娱乐") {
        minimums = [
          {
            //经营类目第一选择框
            value: "物流/快递",
            label: "娱乐票务"
          },
          {
            value: "咨询/法律咨询/金融咨询等",
            label: "运动健身会所"
          },
          {
            value: "婚庆/摄影",
            label: "俱乐部/休闲会所"
          },
          {
            value: "装饰/设计",
            label: "游艺厅/KTV/网吧"
          },
          {
            value: "家政/维修服务",
            label: "院线影城"
          },
          {
            value: "广告/会展/活动策划",
            label: "演出赛事"
          },
          {
            value: "人才中介机构/招聘/猎头",
            label: "美发/美容/美甲店"
          },
          {
            value: "职业社交/婚介/交友",
            label: "酒吧"
          }
        ];
      }
      if (this.value2 === "交通出行/票务旅游") {
        minimums = [
          {
            //经营类目第一选择框
            value: "租车",
            label: "租车"
          },
          {
            value: "加油",
            label: "加油"
          },
          {
            value: "铁路客运",
            label: "铁路客运"
          },
          {
            value: "高速收费",
            label: "高速收费"
          },
          {
            value: "城市公共交通",
            label: "城市公共交通"
          },
          {
            value: "船舶/海运服务",
            label: "船舶/海运服务"
          },
          {
            value: "旅行社",
            label: "旅行社"
          },
          {
            value: "机票/机票代理",
            label: "机票/机票代理"
          },
          {
            value: "汽车用品",
            label: "汽车用品"
          },
          {
            value: "汽车美容/维修保养",
            label: "汽车美容/维修保养"
          },
          {
            value: "停车缴费",
            label: "停车缴费"
          },
          {
            value: "旅馆/酒店/度假区",
            label: "旅馆/酒店/度假区"
          },
          {
            value: "景区",
            label: "景区"
          }
        ];
      }
      if (this.value2 === "网络媒体/计算机服务/游戏") {
        minimums = [
          {
            //经营类目第一选择框
            value: "在线图书/视频/音乐",
            label: "在线图书/视频/音乐"
          },
          {
            value: "门户/资讯/论坛",
            label: "门户/资讯/论坛"
          },
          {
            value: "游戏",
            label: "游戏"
          },
          {
            value: "网络直播",
            label: "网络直播"
          },
          {
            value: "软件/建站/技术开发",
            label: "软件/建站/技术开发"
          },
          {
            value: "网络推广/网络广告",
            label: "网络推广/网络广告"
          }
        ];
      }
      if (this.value2 === "网络服务平台") {
        minimums = [
          {
            //经营类目第一选择框
            value: "团购服务平台",
            label: "团购服务平台"
          },
          {
            value: "综合生活服务平台",
            label: "综合生活服务平台"
          },
          {
            value: "旅游服务平台",
            label: "旅游服务平台"
          },
          {
            value: "订餐服务平台",
            label: "订餐服务平台"
          }
        ];
      }
      if (this.value2 === "教育/医疗") {
        minimums = [
          {
            //经营类目第一选择框
            value: "教育/培训/考试缴费/学费",
            label: "教育/培训/考试缴费/学费"
          },
          {
            value: "民办大学及学院",
            label: "民办大学及学院"
          },
          {
            value: "民办中小幼",
            label: "民办中小幼"
          },
          {
            value: "保健器械/医疗器械/非处方药品",
            label: "保健器械/医疗器械/非处方药品"
          },
          {
            value: "保健信息咨询平台",
            label: "保健信息咨询平台"
          },
          {
            value: "私立/民营医院/诊所",
            label: "私立/民营医院/诊所"
          },
          {
            value: "挂号平台",
            label: "挂号平台"
          }
        ];
      }
      if (this.value2 === "生活缴费") {
        minimums = [
          {
            //经营类目第一选择框
            value: "电信运营商",
            label: "电信运营商"
          },
          {
            value: "宽带收费",
            label: "宽带收费"
          },
          {
            value: "话费通讯",
            label: "话费通讯"
          },
          {
            value: "有线电视缴费",
            label: "有线电视缴费"
          },
          {
            value: "物业管理费",
            label: "物业管理费"
          },
          {
            value: "其他生活缴费",
            label: "其他生活缴费"
          },
          {
            value: "公共事业（水电煤气）",
            label: "公共事业（水电煤气）"
          },
          {
            value: "交通罚款",
            label: "交通罚款"
          }
        ];
      }
      if (this.value2 === "金融") {
        minimums = [
          {
            //经营类目第一选择框
            value: "财经资讯",
            label: "财经资讯"
          },
          {
            value: "股票软件类",
            label: "股票软件类"
          },
          {
            value: "保险业务",
            label: "保险业务"
          },
          {
            value: "众筹",
            label: "众筹"
          },
          {
            value: "信用还款",
            label: "信用还款"
          }
        ];
      }
      if (this.value2 === "收藏拍卖") {
        minimums = [
          {
            //经营类目第一选择框
            value: "非文物类收藏品",
            label: "非文物类收藏品"
          },
          {
            value: "文物经营/文物复制品销售",
            label: "文物经营/文物复制品销售"
          },
          {
            value: "拍卖/典当",
            label: "拍卖/典当"
          }
        ];
      }
      if (this.value2 === "其他") {
        minimums = [
          {
            //经营类目第一选择框
            value: "其他行业",
            label: "其他行业"
          }
        ];
      }
      return minimums;
    },
    // 照片上传data
    uploadData() {
      //文件上传数据
      const { shopId, projectContractorId } = this.alipayData; //点击的支付宝目标
      return { projectId: projectContractorId, shopId };
    }
  },
  methods: {
    // //经营资质照片上传成功
    successData(res, fileList) {
      if (res.state === 1) {
        this.businessQualificationImgId = res.fileId;
        this.DeleteSuccess();
      } else {
        this.DeleteFailure();
      }
    },
    // //营业执照图片上传成功时
    successData2(res, fileList) {
      if (res.state === 1) {
        this.businessLicenseImg = res.fileId;
        this.DeleteSuccess();
      } else {
        this.DeleteFailure();
      }
    },
    // 门店照片上传成功时
    successData3(res, fileList) {
      if (res.state === 1) {
        this.shopImgId = res.fileId;
        this.DeleteSuccess();
      } else {
        this.DeleteFailure();
      }
    },
    // 此方法为点击弹框右上角的 X 时，再次点击详情会出现不显示弹框的问题
    cancel(){
      this.cencel();
    },
    //取消弹框
    cencelChr() {
      this.cencel(); //调用父级方法;
    },
    //   点击确定编辑
    sureEdit() {
      const {
        id,
        mchId,
        shopId,
        businessType,
        projectContractorId,
        aliPayId,
        applyType,
        contactName,
        contactPhone,
        contactEmail
      } = this.applyAliTbs;
      let applyTypes;
      if (this.checked == 1) {
        applyTypes = 1;
      } else if (this.checked == 2) {
        applyTypes = 2;
      } else if (this.checked == 3) {
        applyTypes = 3;
      } else if (this.checked == 4) {
        applyTypes = 4;
      }
      this.$axios
        .get("/mchali/applyEdit", {
          params: {
            applyId: id,
            mchId,
            shopId,
            projectId: projectContractorId,
            aliPayId,
            applyType: applyTypes,
            contactName,
            contactPhone,
            businessType: this.value + "-" + this.value2 + "-" + this.value3,
            contactEmail,
            businessQualificationImgUrl: this.businessQualificationImgId, //经营资质
            businessLicenseImgUrl: this.businessLicenseImgId, //营业执照
            shopImgUrl: this.shopImgId //门店照片
          }
        })
        .then(res => {
          if (res.data.state === 1) {
            this.DeleteSuccess();
          } else {
            this.DeleteFailure();
          }
        });
      this.cencel(); //调用父级方法;
    },
    // 当选择框二value发生变化时，清空选择框三
    remoteMethod() {
      this.value3 = "";
    },
    //操作成功提示
    DeleteSuccess() {
      this.$message({
        message: "恭喜你，操作成功！",
        type: "success"
      });
    },
    //操作失败提示
    DeleteFailure() {
      this.$message.error("错了哦，操作失败！");
    }
  }
};
</script>

<style>
.detail {
  width: 100%;
  padding: 20px 20px 20px 20px;
  background-color: #edf1f3;
  margin-top: 20px;
}

.detail > div {
  color: #606266;
  margin-left: 10px;
}
.detail > div:first-child {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 18px;
}
.detail > div {
  color: #606266;
  margin-left: 10px;
}

.img {
  width: 148px;
  height: 148px;
  border: 1px solid #ccc;
}
.img > img:first-child {
  width: 100%;
  height: 100%;
}
.el-form-item__content {
  display: flex;
}
/* 单选框 */

.el-radio__input.is-checked .el-radio__inner {
  background-color: #00c250;
  border-color: #00c250;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #00c250;
}
</style>